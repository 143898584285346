import React, { Component } from 'react'
import About from './About'
import Work from './Work'
import Skills from './Skills'
import Contact from './Contact'

interface Props {
  article: string
  articleTimeout: boolean
  isArticleVisible: boolean
  onCloseArticle(): void
  timeout: boolean
  setWrapperRef(node: HTMLElement): void
}

class Main extends Component<Props> {
  handleKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === 27 || event.keyCode === 13) {
      this.props.onCloseArticle()
    }
  }

  render() {
    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <About
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          handleKeydown={this.handleKeydown}
          onArticleClose={this.props.onCloseArticle}
        />

        <Skills
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          handleKeydown={this.handleKeydown}
          onArticleClose={this.props.onCloseArticle}
        />

        {/* <Work */}
        {/*   article={this.props.article} */}
        {/*   articleTimeout={this.props.articleTimeout} */}
        {/*   handleKeydown={this.handleKeydown} */}
        {/*   onArticleClose={this.props.onCloseArticle} */}
        {/* /> */}

        <Contact
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          handleKeydown={this.handleKeydown}
          onArticleClose={this.props.onCloseArticle}
        />
      </div>
    )
  }
}

export default Main
