import React, { useEffect, useRef } from 'react'

interface Props {
  article: string
  articleTimeout: boolean
  onArticleClose(): void
  handleKeydown(event: React.KeyboardEvent<HTMLElement>): void
}

const About: React.FC<Props> = ({
  onArticleClose,
  handleKeydown,
  article,
  articleTimeout
}) => {
  const closeButtonRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (closeButtonRef && closeButtonRef.current) {
      closeButtonRef.current.focus()
    }
  })

  return (
    <article
      id="about"
      className={`${article === 'about' ? 'active' : ''} ${
        articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <div
        tabIndex={0}
        ref={closeButtonRef}
        className="close"
        role="button"
        onClick={onArticleClose}
        onKeyDown={handleKeydown}
      />
      <h2 className="major">About</h2>
      <p>
        Frontend focused Fullstack Engineer with relevant experience in various
        web technologies.
      </p>

      <p>
        My specialization lies in Javascript/Node. I also have experience in
        multiple languages libraries/frameworks.
      </p>
    </article>
  )
}

export default About
