import React from 'react'

interface Props {
  timeout: boolean
}

const Footer: React.FC<Props> = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <ul className="icons">
      <li>
        <a
          className="icon fa-twitter"
          href="https://twitter.com/_ANDREWTJAMES"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Link to my Twitter"
        >
          <span className="label">Twitter</span>
        </a>
      </li>
      <li>
        <a
          className="icon fa-linkedin"
          href="https://www.linkedin.com/in/andrewtjames"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Link to my LinkedIn"
        >
          <span className="label">Linked In</span>
        </a>
      </li>
      <li>
        <a
          className="icon fa-github"
          href="https://github.com/andrew-t-james"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Link to my GitHub"
        >
          <span className="label">GitHub</span>
        </a>
      </li>
    </ul>
    <p className="copyright">Andrew James {new Date().getFullYear()}</p>
  </footer>
)

export default Footer
