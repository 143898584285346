import React, { useEffect, useRef } from 'react'
import Babel from '../images/svg/Babel.svg'
import CSS from '../images/svg/CSS.svg'
import Git from '../images/svg/Git.svg'
import Github from '../images/svg/Github.svg'
import Gulp from '../images/svg/Gulp.svg'
import Heroku from '../images/svg/Heroku.svg'
import HTML from '../images/svg/HTML.svg'
import JavaScript from '../images/svg/JavaScript.svg'
import Linux from '../images/svg/Linux.svg'
import Mongo from '../images/svg/Mongo.svg'
import Node from '../images/svg/Node.svg'
import Postgres from '../images/svg/Postgres.svg'
import Python from '../images/svg/Python.svg'
import ReactIcon from '../images/svg/React.svg'
import Sass from '../images/svg/Sass.svg'
import Webpack from '../images/svg/Webpack.svg'
import Docker from '../images/svg/Docker.svg'
import Typescript from '../images/svg/Typescript.svg'
import Gitlab from '../images/svg/Gitlab.svg'

const icons = [
  JavaScript,
  ReactIcon,
  Typescript,
  Webpack,
  Node,
  Babel,
  Git,
  Postgres,
  Gitlab,
  Docker,
  Python,
  Github,
  Sass,
  CSS,
  Gulp,
  HTML,
  Heroku,
  Linux,
  Mongo
]

const renderIcon = (icon: string): JSX.Element => (
  <img className="dev-icon" key={icon} src={icon} />
)

interface Props {
  article: string
  articleTimeout: boolean
  onArticleClose(): void
  handleKeydown
}

const Skills: React.FC<Props> = ({
  onArticleClose,
  handleKeydown,
  article,
  articleTimeout
}) => {
  const closeButtonRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (closeButtonRef && closeButtonRef.current) {
      closeButtonRef.current.focus()
    }
  })

  return (
    <article
      id="skills"
      className={`${article === 'skills' ? 'active' : ''} ${
        articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <div
        tabIndex={0}
        ref={closeButtonRef}
        className="close"
        role="button"
        onClick={onArticleClose}
        onKeyDown={handleKeydown}
      />
      <h2 className="major">Skills</h2>
      <div className="dev-icons">{icons.map(renderIcon, icons)}</div>
    </article>
  )
}

export default Skills
