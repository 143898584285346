import React from 'react'

interface Props {
  timeout: boolean
  onOpenArticle(element: string): void
}

const Header: React.FC<Props> = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      A <span style={{ color: 'orange', fontWeight: 700 }}>J</span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Andrew James</h1>
        <p>Fullstack engineer</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:"
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </a>
        </li>
        <li>
          <a
            href="javascript:"
            onClick={() => {
              props.onOpenArticle('skills')
            }}
          >
            Skills
          </a>
        </li>
        {/* <li> */}
        {/*   <a */}
        {/*     href="javascript:" */}
        {/*     onClick={() => { */}
        {/*       props.onOpenArticle('work') */}
        {/*     }} */}
        {/*   > */}
        {/*     Work */}
        {/*   </a> */}
        {/* </li> */}
        <li>
          <a
            href="javascript:"
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
