import React, { Component } from 'react'

interface Props {
  article: string
  articleTimeout: boolean
  onArticleClose(): void
  handleKeydown(event: React.KeyboardEvent<HTMLElement>): void
}

interface State {
  [key: string]: string
}

class Contact extends Component<Props, State> {
  closeButtonRef = React.createRef<HTMLDivElement>()

  state = {
    name: '',
    email: '',
    message: ''
  }

  componentDidUpdate(prevProps: { articleTimeout: boolean }) {
    const { articleTimeout } = this.props

    if (prevProps.articleTimeout !== articleTimeout) {
      this.closeButtonRef.current.focus()
    }
  }

  encode = (data: { [k: string]: string }) =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')

  handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': 'contact', ...this.state })
    })
      .then(() => this.setState({ name: '', email: '', message: '' }))
      .catch(error => alert(error))
  }

  handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  render() {
    const { name, email, message } = this.state
    const {
      article,
      articleTimeout,
      onArticleClose,
      handleKeydown
    } = this.props

    return (
      <article
        id="contact"
        className={`${article === 'contact' ? 'active' : ''} ${
          articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <div
          tabIndex={0}
          ref={this.closeButtonRef}
          className="close"
          role="button"
          onClick={onArticleClose}
          onKeyDown={handleKeydown}
        />
        <div>
          <h2 className="major">Contact</h2>
          <form
            method="POST"
            action="/"
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            acceptCharset="UTF-8"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={name}
                id="name"
                onChange={this.handleChange}
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={email}
                id="email"
                onChange={this.handleChange}
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                value={message}
                rows={4}
                onChange={this.handleChange}
              />
            </div>
            <ul className="actions">
              <li>
                <button type="submit" value="Send" className="special">
                  Submit
                </button>
              </li>
              <li>
                <button type="reset" value="Reset">
                  Reset
                </button>
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                className="icon fa-twitter"
                href="https://twitter.com/_ANDREWTJAMES"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/andrewtjames"
                className="icon fa-linkedin"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="label">Linked In</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/andrew-t-james"
                className="icon fa-github"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
        </div>
      </article>
    )
  }
}

export default Contact
